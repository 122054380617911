import {
  CreateClaimRequestCollisionScenario,
  CreateClaimRequestDirectionOfTravel,
} from "raci-claims-motor-collision-clientproxy";
import {
  YesNo,
  YesNoImNotSure,
  YesNoUnknown,
  useGetSessionState,
  useSessionStateInitialiser,
} from "raci-react-library";
import { useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { OtherVehiclesInvolved } from "../../../views/AboutTheAccident/constants";
import { AboutTheAccidentState } from "../../../views/AboutTheAccident/types";
import { AboutYourCarState } from "../../../views/AboutYourCar/types";
import { MoreAboutTheAccidentState } from "../../../views/MoreAboutTheAccident/types";
import { RepairerOptionsState } from "../../../views/RepairerOptions/types";
import { ReviewYourClaimState } from "../../../views/ReviewYourClaim/types";
import { StartYourClaimState } from "../../../views/StartYourClaim/types";
import { ThirdPartyDetailsState } from "../../../views/ThirdPartyDetails/types";
import useFlowState from "../../components/FlowStateProvider/useFlowState";
import { STORAGE_KEY_POLICY_NUMBER } from "../../constants";

import {
  FormPageName,
  FormRoute,
  RouteInformation,
  formRoutes as formRoutesArray,
  preFormRoutes,
} from "../routes.config";

export interface StepInformation {
  name: string;
  path: string;
  heading?: string;
}

export interface UseRoutesReturn {
  formRoutes: RouteInformation[];
  steps: StepInformation[];
  isActiveFlow: boolean;
  activeStepIndex: number;
  totalStepCount: number;
  navigateToPreviousStep: () => void;
  canNavigateToPreviousStep: boolean;
  isReviewClaimCompleted: boolean;
}

export const useRoutes = (): UseRoutesReturn => {
  const location = useLocation();
  const navigate = useNavigate();
  const initialiseSessionStates = useSessionStateInitialiser();
  const [{ isFlowCompleted }] = useFlowState();
  const { hasComprehensiveCover } = useGetSessionState<StartYourClaimState>(FormRoute.StartYourClaim);
  const {
    otherVehiclesInvolved,
    propertyDamaged,
    claimForDamageToOwnCar,
    isCompleted: isAboutTheAccidentCompleted,
  } = useGetSessionState<AboutTheAccidentState>(FormRoute.AboutTheAccident);
  const { carIsDriveable, isCompleted: isAboutYourCarCompleted } = useGetSessionState<AboutYourCarState>(
    FormRoute.AboutYourCar,
  );
  const { isCompleted: isReviewClaimCompleted } = useGetSessionState<ReviewYourClaimState>(FormRoute.ReviewYourClaim);
  const { repairers } = useGetSessionState<RepairerOptionsState>(FormRoute.RepairerOptions);
  const { hasOwnerDetails, isCompleted: isThirdPartyDetailsCompleted } = useGetSessionState<ThirdPartyDetailsState>(
    FormRoute.ThirdPartyDetails,
  );
  const { directionOfTravel, multiVehicleCollisionScenario } = useGetSessionState<MoreAboutTheAccidentState>(
    FormRoute.MoreAboutTheAccident,
  );

  const enteredFlowWithSinglePolicy = !!sessionStorage.getItem(STORAGE_KEY_POLICY_NUMBER);

  const isSingleVehicleCollisionClaim =
    otherVehiclesInvolved === OtherVehiclesInvolved.None || otherVehiclesInvolved === OtherVehiclesInvolved.ImNotSure;
  const isLiabilityOnlyClaim = !hasComprehensiveCover || claimForDamageToOwnCar === YesNo.No;

  useEffect(() => {
    initialiseSessionStates(
      [...preFormRoutes, ...formRoutesArray].map(({ key, path }) => ({
        page: key,
        path,
      })),
    );
  }, [initialiseSessionStates, location.pathname]);

  const formRoutes = useMemo(() => {
    let availableRoutes = [...formRoutesArray];

    if (enteredFlowWithSinglePolicy) {
      availableRoutes = availableRoutes.filter((route) => route.key !== FormRoute.YourPolicy);
    }
    if (!isAboutTheAccidentCompleted || (isSingleVehicleCollisionClaim && propertyDamaged !== YesNoUnknown.Yes)) {
      availableRoutes = availableRoutes.filter(
        (route) => route.key !== FormRoute.ThirdPartyDetails && route.key !== FormRoute.MoreThirdPartyDetails,
      );
    }
    if (!isThirdPartyDetailsCompleted || hasOwnerDetails === YesNo.No) {
      availableRoutes = availableRoutes.filter((route) => route.key !== FormRoute.MoreThirdPartyDetails);
    }
    if (!hasComprehensiveCover || !isAboutTheAccidentCompleted || isLiabilityOnlyClaim) {
      availableRoutes = availableRoutes.filter((route) => route.key !== FormRoute.AboutYourCar);
    }
    if (isLiabilityOnlyClaim || !isAboutYourCarCompleted || carIsDriveable === YesNoImNotSure.Yes) {
      availableRoutes = availableRoutes.filter((route) => route.key !== FormRoute.WheresYourCar);
    }
    if (!isReviewClaimCompleted || !repairers) {
      availableRoutes = availableRoutes.filter((route) => route.key !== FormRoute.RepairerOptions);
    }
    if (
      directionOfTravel === CreateClaimRequestDirectionOfTravel.Parked &&
      !isSingleVehicleCollisionClaim &&
      multiVehicleCollisionScenario === CreateClaimRequestCollisionScenario.HitByVehicle
    ) {
      availableRoutes = availableRoutes.filter((route) => route.key !== FormRoute.DriverHistory);
    }

    const thirdPartyRoute = availableRoutes.find((route) => route.key === FormRoute.ThirdPartyDetails);
    if (thirdPartyRoute) {
      thirdPartyRoute.name = isSingleVehicleCollisionClaim
        ? FormPageName.PropertyOrPetOwner
        : FormPageName.OtherVehicleDriver;
    }

    const moreThirdPartyRoute = availableRoutes.find((route) => route.key === FormRoute.MoreThirdPartyDetails);
    if (moreThirdPartyRoute) {
      moreThirdPartyRoute.name = isSingleVehicleCollisionClaim
        ? FormPageName.MoreOnTheOwner
        : FormPageName.OtherVehicleOwner;
    }

    return availableRoutes;
  }, [
    enteredFlowWithSinglePolicy,
    isAboutTheAccidentCompleted,
    isSingleVehicleCollisionClaim,
    propertyDamaged,
    isThirdPartyDetailsCompleted,
    hasOwnerDetails,
    hasComprehensiveCover,
    isLiabilityOnlyClaim,
    isAboutYourCarCompleted,
    carIsDriveable,
    isReviewClaimCompleted,
    repairers,
    directionOfTravel,
    multiVehicleCollisionScenario,
  ]);

  const isActiveFlow = formRoutes.some((item) => item.path === location.pathname);

  const steps = useMemo(
    () => (isActiveFlow ? formRoutes.map(({ name, path }) => ({ name, path }) satisfies StepInformation) : []),
    [formRoutes, isActiveFlow],
  );

  const activeStepIndex = steps.findIndex((item) => item.path === location.pathname);

  const navigateToPreviousStep = useMemo(
    () => () => isActiveFlow && activeStepIndex > 0 && navigate(steps[activeStepIndex - 1].path, { replace: true }),
    [isActiveFlow, activeStepIndex, navigate, steps],
  );

  const canNavigateToPreviousStep = useMemo(
    () => activeStepIndex !== 0 && !isFlowCompleted && !isReviewClaimCompleted,
    [activeStepIndex, isFlowCompleted, isReviewClaimCompleted],
  );

  return {
    formRoutes,
    steps,
    isActiveFlow,
    activeStepIndex,
    totalStepCount: steps.length,
    navigateToPreviousStep,
    canNavigateToPreviousStep,
    isReviewClaimCompleted: !!isReviewClaimCompleted,
  };
};

export default useRoutes;
